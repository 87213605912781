@font-face {
  font-family: 'Nafees';
  src: url('font/NafeesNastaleeq.woff') format('woff'), /* Chrome 6+, Firefox 3.6+, IE 9+, Safari 5.1+ */
   url('font/Nafees-Nastaleeq.ttf') format('truetype'); /* Chrome 4+, Firefox 3.5, Opera 10+, Safari 3—5 */
}
@font-face {
  font-family: 'Adobe';
  src: url('font/AdobeArabicRegular.ttf') format('truetype'); /* Chrome 6+, Firefox 3.6+, IE 9+, Safari 5.1+ */
}
 
div#root {
  overflow-x: hidden;
} 
h1,h2,h3,h4,h5,h6{
  line-height: 1.5;
  margin: 0 0 1rem;
}
.container {
  max-width: 1200px;
  margin: 0 auto;
  position: relative;
}
.row {
  padding-left: 15px;
  padding-right: 15px;
  display: flex;
  flex-wrap: wrap;
}
.col-md-12 {
  width: 100%;
}
.onlyMobile {
  display: none;
}
.col-md-6 {
  width: 50%;
  position: relative;
}
.rhap_additional-controls {
  flex: 0 0 auto !important;
}
.rhap_container svg {
  vertical-align: top !important;
}
.popupModal {
  background-color: #333333db;
  position: fixed;
  height: 100%;
  width: 100%;
  top: 0;
  left: 0;
  z-index: 9999999;
}
.hubspotForm {
  max-width: 600px;
  margin: 5px auto;
  background-color: #fff;
  padding: 2rem 2rem 1rem;
  border-radius: 6px;
  top: 50%;
  transform: translate(0, -50%);
  position: relative;
}
.popupModal i {
  position: absolute;
  right: 50%;
  top: 50%;
  transform: translate(300px, -300px);
  display: block;
  z-index: 9999;
  font-size: 20px;
  cursor: pointer;
  color: #333;
  width: 30px;
  height: 30px;
  border: 2px solid #333;
  text-align: center;
  line-height: 1.4;
  border-radius: 50px;
}
.hubspotForm h2 {
  font-family: 'circular';
  line-height: normal;
}
.hubspotForm p {
  margin-top: -10px;
  margin-bottom: 2rem;
  font-family: 'circular';
}
.liveGif {
  position: fixed;
  right: 0;
  top: 50%;
  border: 1px solid #000;
  border-radius: 4px;
  background-color: #fff;
  z-index: 99999;
  padding: 4px;
}
.liveGif iframe {
  height: 20px;
  width: 50px;
  pointer-events: none;
}
.rhap_container {
  box-shadow: 0 0 3px 0 #30829129 !important;
  margin-top: -6px;
  padding-left: 6px !important;
  padding-bottom: 4px !important;
}
.rhap_progress-indicator {
  width: 14px !important;
  height: 14px !important;
  margin-left: 0 !important;
  top: -5px !important;
}
.col-md-5 {
  width: 40%;
  position: relative;
}
.col-md-4 {
  width: 33.3333%;
  position: relative;
}
.col-md-3 {
  width: 25%;
  position: relative;
}
.col-md-2 {
  width: 20%;
  position: relative;
}
h1,h2,h3,h4,h5,h6,p,li,a,span,label, input, button{
  font-family: 'Nafees';
}
.Top_bar {
  background-color: #0a8191;
  padding: 0.8rem 0;
}
.Search_bar form {
  background: #fff;
  max-width: fit-content;
  margin-left: auto;
  border-radius: 4px;
}
.Search_bar input[type="text"] {
  text-align: right;
  padding: 4px 16px 0 0;
  width: 230px;
  border: none;
  outline: none;
  font-family: 'Nafees';
  line-height: 2;
  font-size: 14px;
}
.Search_bar input::placeholder, .email_subscribe input::placeholder { 
  padding-bottom: 10px;
  padding-top: 10px;
}
.Search_bar select {
  font-family: 'Nafees';
  font-size: 15px;
  text-align: right;
  border: none;
  background: transparent;
  outline: none;
  padding: 0 12px;
  color: #308291; 
  -webkit-appearance: none;
  line-height: 2;
}
.Search_bar input::placeholder, .news_letter input::placeholder {
  line-height: 2;
}

.Search_bar button {
  position: relative;
  background: transparent;
  border: none;
  outline: none;
  cursor: pointer;
  font-size: 16px;
  left: 6px;
  line-height: 2;
}
.searchAll .row {
  margin: 2rem 0;
}
.Logo {
  background-size: cover;
  text-align: center;
  padding: 6px 0px;
}
.Logo img {
  width: 350px;
}
.MenuList {
  background-size: 100% 100%;
  position: absolute;
  top: 0rem;
  z-index: 9999;
  width: 75%;
  left: 12.5%;
}

.MenuList ul {
  text-align: center;
  padding: 0;
  margin: 0;
}
.MenuList li {
  display: inline-block;
  color: #fff;
  position: relative;
  padding: 0 24px;
}
.MenuList li a:after {
  content: "";
  right: 0;
  position: absolute;
  height: 38px;
  left: 0px;
  top: 14px;
  width: 1px;
  background-color: #fff;
}
.MenuList li a {
  text-decoration: none;
  color: #fff;
  height: 62px;
  font-size: 18px;
  font-family: Nafees;
  display: flex;
  padding-top: 2px;
  align-items: center;
}
.ft_menu a {
  color: #333;
  text-decoration: none;
}
/* .MenuList li:hover {
  background: #fff;
  color: #333;
  transition: 0.4s;
} */
.MenuList li:after {
  content: "";
  width: 0px;
  height: 3px;
  position: absolute;
  left: 0;
  top: 0;
  right: 0;
  margin: 0 auto;
  background: #c19b76;
  -webkit-transition: all .5s ease-in-out;
  -moz-transition: all .5s ease-in-out;
  -o-transition: all .5s ease-in-out;
  transition: all .5s ease-in-out;
}
.MenuList li:hover:after {
  width: 100%;
}
.MenuList li:hover > a {
  color: #000;
}
.MenuList li i {
  position: absolute;
  left: 10px;
  top: 1.8rem;
  font-size: 14px;
  font-weight: bold;
}
.firstLevel li i {
  top: 1rem !important;
}
.MenuList span {
  font-size: 28px;
  position: relative;
  color: #fff;
  top: 18px;
}


.col_reverse {
  display: flex;
  flex-flow: row-reverse;
  justify-content: center;
}
ul.col_reverse .firstLevel {
  border-top: 2px solid #fff;
}
ul.col_reverse li:last-child a:after {
  display: none;
}
/* First Level Menu */
ul.firstLevel {
  position: absolute;
  background: #308291;
  top: 4rem;
  right: 0px;
  opacity: 0;
  visibility: hidden;
  transition: 0.4s;
  width: max-content;
}
ul.firstLevel li {
  display: block;
  position: relative;
  text-align: right;
  border-bottom: 1px dotted #fff;
  padding: 0 16px 0 24px;
}
ul.firstLevel li a {
  font-size: 16px;
  text-align: right;
  display: flex;
  height: 44px;
  padding-top: 0;
  justify-content: flex-end;
}
.MenuList li:hover ul {
  opacity: 1;
  visibility: visible;
}
ul.firstLevel a:after {
  display: none;
}

ul.secondLevel {
  position: absolute;
  background: #308291;
  top: 0;
  left: -200px;
  width: 200px;
  visibility: hidden;
  transition: 0.4s;
  opacity: 0 !important;
  height: 0px;
  overflow: hidden;
  border-right: 1px solid #fff;
}

.firstLevel li:hover ul {
  opacity: 1 !important;
  visibility: visible;
  height: auto;
  overflow: visible;
}

ul.secondLevel a {
  padding-left: 1rem !important;
}



.Banner img {
  width: 100%;
}
.navButtons {
  background-size: cover;
  background-repeat: no-repeat;
  padding: 3rem 0;
}
.navButtons .row {
  justify-content: space-between;
}
.navButtons h1 {
  font-size: 38px;
  font-weight: 500;
  width: 100%;
  line-height: 1.5;
  margin: 0px;
}
.navButtons .col-md-3 {
  /* margin-top: -8rem; */
  position: relative;
  overflow: hidden;
  width: 24%;
}
.navBg {
  margin-bottom: -6px;
}
.navButtons .col-md-3:hover .navBg {
  transform: scale(1.5);
  transition: 1s;
}
.navBg img {
  width: 100%;
}
.navButtons .navContent {
  text-align: center;
  color: #fff;
  display: block;
  margin-right: 10px;
  text-decoration: none;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 100%;
}
.nasharyat img {
  width: 60%;
  padding-top: 8px;
}
.nasharyat {
  background-color: #3e3a3f;
  text-align: center;
}
.navContent img {
  height: 150px;
}
.nasharyat .nasharyat-btn {
  display: flex;
  background-color: #cdcdcd;
  height: 34px;
  align-items: center;
  color: #333;
  margin: 8px 24px 14px;
  justify-content: center;
  border-radius: 50px;
  text-decoration: none;
}
.shortClipsBox {
  text-align: right;
  color: #fff;
  padding-right: 10px;
}
.shortClipsBox h3 {
  height: 38px;
  overflow: hidden;
  line-height: 2.5;
}
.shortClipsBox img {
  width: 100%;
  display: block;
  cursor: pointer;
}
.shortClips {
  padding: 3rem 0;
}
.shortClips h1 {
  text-align: right;
  color: #fff;
  line-height: 2;
  margin: 0;
}
.react-multiple-carousel__arrow {
  min-width: 30px !important;
  min-height: 30px !important;
}
.react-multiple-carousel__arrow::before {
  font-size: 14px !important;
}
.shortClips .react-multiple-carousel__arrow {
  top: 50%;
}
.shortClips .react-multiple-carousel__arrow--right {
  right: calc(2% + 1px);
}
.shortClips .react-multiple-carousel__arrow--left {
  left: calc(2% + 1px);
}

.dark_btn {
  background-color: #000;
  color: #fff;
  height: 36px;
  text-decoration: none;
  margin-top: 1rem;
  display: flex;
  align-items: center;
  width: 100px;
  justify-content: center;
  border-radius: 2px;
}
.dark_btn:hover {
  color: #000;
}
.imageSlide {
  background-color: #000;
  text-align: center;
  border-radius: 4px;
  margin: 0 auto;
  padding: 14px 6px;
  width: 92%;
}
.imageSlide img {
  height: 140px;
  display: block;
  margin: 0 auto;
  cursor: pointer;
}
.imageBg {
  padding: 3rem 0;
  background-size: cover;
}
.imageBg h1 {
  line-height: 2;
  text-align: right;
  margin: 0;
}
.featuredImage h1 {
  padding-right: 22px;
  padding-bottom: 12px;
}
.imageBg .dark_btn, .shortClips .dark_btn {
  margin-left: 12px;
  position: relative;
  z-index: 1;
}
.white h1 {
  color: #fff;
}
.white .featuredTitle {
  color: #fff;
}



.dataFilter {
  padding-top: 2rem;
  display: flex;
  justify-content: right;
}
.fDuration {
  margin: 0 1.5rem;
}
.lDuration {
  padding-right: 2rem;
}
.dataFilter i {
  margin-left: -1.6rem;
  font-size: 18px;
  color: #308291;
  position: relative;
  top: 1px;
}

.dataFilter select {
  padding: 6px 28px 6px 6px;
  outline: none;
  border: 2px solid #ccffff;
  background: #fff;
  border-radius: 4px;
  width: 180px;
  -webkit-appearance: none;
  -moz-appearance: none;
  cursor: pointer;
}

.activeAudio .left_col {
  text-align: center;
  width: 95%;
  position: relative;
}
.activeAudio p {
  font-size: 16px;
  line-height: 1.8;
}
.activeAudio {
  padding: 3rem 0 2rem;
  justify-content: center;
}
/* .activeAudio .right_col {
  height: 320px;
  vertical-align: middle;
  display: table-cell;
  text-align: right;
} */

.activeAudio .right_col {
  text-align: right;
}

.activeAudio h4 {
  font-size: 26px;
  margin-top: 0px;
  margin-bottom: 18px;
  font-weight: 100;
  text-align: center;
}

.activeAudio .thumb {
  width: 100%;
}

.playIcon {
  position: absolute;
  width: 100%;
  background: #3333332e;
  height: 300px;
}
.playIcon img {
  height: 70px;
  margin-top: 115px;
  cursor: pointer;
}

.activeAudio h1 {
  color: #308291;
  margin: 1rem 0;
  font-size: 26px;
}
audio {
  background: #f1f3f4;
  width: 100%;
  margin-top: -3px;
  height: 34px;
}
.audio_row {
  margin: 2rem 0 3rem;
}
.audioStyle {
  text-align: right;
  margin: 1rem 8px 0;
  position: relative;
  cursor: pointer;
}
.audioStyle .socialSharing {
  right: 0.5rem;
  top: 3rem;
}
.audioStyle .thumb {
  width: 100%;
}
.audioTitle h5, .audioTitle h6 {
  color: #fff;
  margin: 0px;
  padding: 4px 0;
  height: 22px;
  overflow: hidden;
  line-height: 1.5;
}
.audioTitle {
  background: #308291;
  margin-top: -3px;
  padding: 6px 12px 2px 8px;
  cursor: pointer;
}
.pageTitle h1 {
  margin: 0;
  padding: 7rem 0 2.5rem;
  font-size: 34px;
  font-weight: 100;
  color: #308291;
  line-height: 1.5;
}
.pageTitle {
  background: #ccffff;
  text-align: center;
}
.pagination ul {
  display: flex;
  justify-content: flex-end;
  flex-wrap: wrap;
  margin-bottom: 2.5rem;
}
.pagination li {
  list-style: none;
}
.pagination a {
  list-style: none;
  border: 1px solid #ddd;
  font-size: 18px;
  color: #308291;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  height: 35px;
  width: 35px;
}
.pagination a:hover, .pagination .selected a {
  background: #308291;
  color: #fff;
  transition: 0.4s;
}
.pagination ul {
  display: flex;
  justify-content: flex-end;
  flex-wrap: wrap;
  margin-bottom: 2.5rem;
  margin-right: 1.4rem;
}
.onloadAnimate {
  z-index: 99999;
  position: fixed;
  height: 100%;
  width: 100%;
  left: 0;
  top: 0;
}
.onloadAnimate:before {
  content: '';
  position: absolute;
  background: #fff;
  height: 100vh;
  width: 50%;
  top: 0;
  left: 0;
  z-index: 999999;
}
.onloadAnimate:after {
  content: '';
  position: absolute;
  background: #fff;
  height: 100vh;
  width: 50%;
  top: 0;
  right: 0;
  z-index: 999999;
}

.onloadAnimate.loaded:after, .onloadAnimate.loaded:before {
  transition: 1s;
  width: 0;
}
.onloadAnimate.loaded {
  visibility: hidden !important;
}
.onloadAnimate img {
  z-index: 9999999;
  position: absolute;
  height: 160px;
  top: 50%;
  left: 50%;
  margin-left: -80px;
  margin-top: -120px;
}
.onloadAnimate.loaded img{
  display: none;
}



.ImageGallery {
  margin: 2rem 0rem;
}

.image_pdf {
  margin-bottom: 1rem;
}

.image_pdf img {
  width: 95%;
  cursor: pointer;
  height: 400px;
}

#fullImage {
  text-align: center;
  background: #333333db;
  position: fixed;
  display: none;
  height: 100%;
  width: 100%;
  top: 0;
  left: 0;
  z-index: 99999;
  overflow-y: scroll;
  scrollbar-width: none;
}
#fullImage::-webkit-scrollbar {
  display: none;
}
#changeSrc {
  width: 600px;
  object-fit: contain;
  margin-top: 3rem;
  margin-bottom: 3rem;

}
#closeBtn {
  color: #fff;
  position: absolute;
  border: 2px solid #fff;
  border-radius: 50px;
  top: 3rem;
  margin-left: 1rem;
  font-size: 22px;
  height: 40px;
  width: 40px;
  cursor: pointer;
  line-height: 1.8;
}



/* Books Page Css */

.booksGrid {
  margin: 3rem 0;
}
.bookCard {
  box-shadow: 5px 5px 10px #cbfcfd;
  margin-right: 1rem;
  border-radius: 4px;
  padding-bottom: 2px;
  text-align: right;
  margin-bottom: 1.5rem;
  cursor: pointer;
}

.bookCard img {
  width: 100%;
  border-radius: 6px 6px 0 0;
}
.bookCard h5, .bookCard h6 {
  margin: 12px;
}

.socialSharing {
  position: absolute;
  right: 1.5rem;
  top: 0.5rem;
  background: #fff;
  padding: 4px 2px;
  border-radius: 50px;
  white-space: nowrap;
  width: 26px;
  overflow: hidden;
  transition: 0.8s;
}
.socialSharing:hover {
  width: 84px;
}
.socialSharing a {
  height: 24px;
  width: 24px;
  margin: 0 2px;
  border-radius: 50px;
  text-align: center;
  display: inline-block;
  text-decoration: none;
  line-height: 1.5;
}
.facebook_i {
  background: #3B5998;
  color: #fff;
}
.whatsapp_i {
  background: green;
  color: #fff;
}
.share_i {
  color: #308291;
  margin-left: 0px !important;
}
.row.bookDetail {
  justify-content: center;
  margin-top: 3rem;
  margin-bottom: 3rem;
}
.img_side img {
  width: 100%;
}
.des_side {
  text-align: right;
  padding-right: 3rem;
}
.des_side h1 {
  color: #308291;
  font-size: 26px;
}
.des_side h4 {
  color: #308291;
  font-weight: bold;
}
.des_side p {
  font-size: 16px;
    line-height: 1.8;
}
.imageIcon img {
  height: 70px;
  margin-left: 1rem;
}
.featuredData {
  width: 85%;
  margin: 0 auto;
}

.featuredData p {
  font-size: 18px;
  text-align: right !important;
  line-height: 2;
  margin: 0;
  padding-bottom: 1rem;
}
.featureDataHeight {
  max-height: 252px;
  overflow: hidden;
  margin-bottom: 2rem !important;
}
.white p {
  color: #fff;
}
.featuredData img {
  display: block;
  margin-left: auto;
  height: 230px;
  margin-top: -2rem;
  margin-bottom: -18px;
}
span.featuredTitle {
  font-size: 30px;
  font-weight: bold;
  display: block;
  text-align: right;
  line-height: 2;
}
.featuredData h4, .featuredData h1, .featuredData h3, .featuredData h2 {
  text-align: right !important;
  margin: 0 0 6px;
  font-size: 18px;
}
.featuredData h4 {
  font-family: "Adobe";
  font-size: 22px;
}
.newBook {
  position:relative;
}
.newBook p {
  max-width: 300px;
  margin-left: auto;
}
.newBook img {
  margin-left: 0px;
  position: absolute;
  height: 200px;
  top: 50%;
  transform: translate(0, -50%);
}
.ahamBayan {
  width: 80%;
  margin: 0 auto;
}
.ahamBayan p {
  font-size: 18px;
  text-align: right;
  line-height: 2;
  width: 85%;
  margin-left: auto;
}
.ahamBayan h4 {
  color: #fff;
  text-align: right;
  margin-top: 3rem;
  font-family: 'Nafees';
  margin-bottom: -0.5rem;
}
.audioDesign h3 {
  width: 75%;
  margin-left: auto;
  color: #fff;
  font-size: 16px;
  margin-bottom: 0;
}
.audioDesign img {
  width: 80px;
  float: left;
  margin-top: -4px;
}
.audioDesign {
  text-align: right;
  background: #232323;
  width: 75%;
  margin-left: auto;
  margin-top: 1.5rem;
  padding: 10px;
  border-radius: 4px;
  margin-bottom: -1.5rem;
}
.audioDesign audio {
  width: 75%;
  border-radius: 50px;
  margin: 0 0 14px;
}
.footer_section {
  background-size: cover;
  text-align: right;
}
.footer_section {
  background-size: cover;
  text-align: right;
  padding: 2rem 0px 1rem;
}
.footer_section .row {
  justify-content: space-between;
}
.ft_menu li {
  font-size: 16px;
  list-style: none;
  height: 34px;
}
.ft_menu h1 {
  margin-bottom: 0;
  font-size: 26px;
  height: 38px;
}
.news_letter p {
  font-size: 14px;
  line-height: 2;
}

.news_letter img {
  height: 40px;
  margin: 0 2px;
}
.news_letter button img {
  position: relative;
  bottom: -2rem;
  right: -2rem;
  height: auto ;
}
.news_letter input[type="email"] {
  padding: 4px 14px;
  text-align: right;
  font-family: 'Nafees';
  line-height: 2;
  outline: none;
  background-color: #e1dddd;
  border: none;
  width: 64%;
  font-size: 18px;
}
.news_letter button {
  background: transparent;
  border: none;
  cursor: pointer;
}
.formMessage {
  display: block;
  font-size: 14px;
  margin-top: 12px;
}
.audioPlayer {
  border: 1px solid #8686864a;
  padding: 4px 4px 0px;
  margin-top: -3px;
  background-color: #fff;
}

.audioPlayer img {
  width: 100%;
}
div#audioFull {
  position: fixed;
  width: 100%;
  height: 100%;
  background-color: #333333;
  top: 0;
  z-index: 9999;
  padding-top: 20vh;
}
#audioFull h1, #audioFull p {
  color: #fff;
}
#audioFull #closeBtn {
  right: 50%;
  margin-right: -25rem;
  top: 2rem;
  text-align: center;
}
#audioFull audio {
  height:60px;
}
.email_subscribe {
  margin: -1.5rem 0 2rem;
}
.news_letter {
  padding-top: 1rem;
  position: relative;
  margin-right: -6rem;
}
.footer_logo img {
  width: 150px;
}
.footer_logo p {
  font-size: 14px;
  margin: 10px 0px;
}
.footer_logo a {
  text-decoration: none;
  color: #333;
}
.footer_logo img {
  width: 160px;
  margin: 0 auto;
  display: block;
}
.hamBurger {
  display: none;
}
.textPages {
  margin: 2rem auto 2rem;
}
.textPages p {
  text-align: center;
  line-height: 2;
}
.textPages h1, .textPages h5 {
  text-align: center;
}
.textPages h4 {
  font-family: 'Adobe';
  font-size: 22px;
  font-weight: 100;
}
.textPages h1 {
  margin-top: 2rem;
  font-weight: 500;
}
.khatoot {
  text-align: right;
} 
.khatoot h3 {
  margin-top: 3rem;
}
.khatoot ul {
  padding-right: 1rem;
}
.khatoot li, .khatoot p {
  line-height: 2;
  list-style: none;
}
.khatoot .col-md-12, .khatoot .col-md-6 {
  padding-top: 3rem;
  padding-bottom: 2rem;
}

.khatoot form {
  padding-top: 2rem;
}
.khatoot input, .khatoot textarea {
  width: 50%;
  padding: 0 14px;
  margin: 1rem 0 2rem;
  border: 1px solid #e2e2e2;
  border-radius: 4px;
  text-align: right;
}
.khatoot input {
  line-height: 3;
}
.khatoot textarea {
  height: 100px;
  padding: 14px;
}
.khatoot label {
  display: block;
}
.khatoot button {
  display: block;
  margin-left: auto;
  height: 36px;
  padding: 0 2rem;
  background: #333;
  color: #fff;
  border-radius: 4px;
  margin-bottom: 2rem;
  cursor: pointer;
}
.announcement .row {
  margin: 3rem 0;
}
.announceBox {
  box-shadow: 0px 0px 10px #cbfcfd;
  padding: 1.5rem 1rem;
  margin: 0.5rem;
  text-align: right;
  border-radius: 6px;
}
.announceBox p {
  font-size: 14px;
  line-height: 1.8;
}
.announceBox p.ReactHtmlParser {
  height: 300px;
  overflow: hidden;
}
.announceBox a {
  text-decoration: none;
  font-size: 12px;
  color: #333;
  border: 1px solid #333;
  height: 28px;
  line-height: 2;
  display: flex;
  align-items: center;
  max-width: 70px;
  justify-content: center;
  border-radius: 3px;
  margin-top: 1.5rem;
  transition: 0.4s;
}
.announceBox a:hover {
  background-color: #333;
  color: #fff;
}
.copyright p {
  text-align: center;
  margin-top: 0px;
  font-family: inherit;
  font-size: 14px;
}
.copyright a {
  font-family: inherit;
  text-decoration: none;
}



@media screen and (min-width: 768px) {

.MenuList li:before, .dark_btn:before {
  position: absolute;
  right: 0;
  bottom: 0;
  background: #fff;
  width: 100%;
  height: 0px;
  -webkit-transition: all .4s ease-in-out;
  -moz-transition: all .4s ease-in-out;
  -o-transition: all .4s ease-in-out;
  transition: all .4s ease-in-out;
  content: '';
  z-index: -1;
}
.MenuList li:hover:before, .dark_btn:hover:before {
  height: 100%;
}
ul.firstLevel {
  z-index: -2;
}
ul.secondLevel {
  z-index: -2;
}

}

@media screen and (max-width: 768px) {

  .row{
    justify-content: space-between;
  }
  .col-md-6, .col-md-4 {
    width: 100%;
}
.announcement .col-md-3 {
  width: 100%;
}
.activeAudio h1 {
  line-height: 1.5;
}
.onlyMobile {
  display: block;
}
p {
  font-size: 14px !important;
}

.popupModal i {
  transform: translate(166px, -286px);
}
.hubspotForm {
  margin: 5px 0.5rem;
  padding: 3rem 1rem 0rem;
}
div#audioFull {
  padding-top: 0rem;
  overflow-y: auto;
}
div#audioFull .row {
  padding-top: 6rem;
}
#audioFull #closeBtn {
  right: 1rem;
  margin-right: 0;
  z-index: 9999;
}
#audioFull audio {
  height: 44px;
}
.row.bookDetail {
  flex-direction: column-reverse;
  margin-bottom: 1rem;
}
.row.bookDetail .img_side {
  margin-bottom: 2rem;
}

/* .imageBg.naye_kitab, .imageBg.naya_shumara, .bgRemove {
  background-size: contain;
} */
.imageBg.naye_kitab, .imageBg.naya_shumara {
  padding: 3rem 0 1rem;
}
.react-multiple-carousel__arrow::before {
  font-size: 12px !important;
}
.dark_btn {
  color: #fff !important;
}
.hamBurger {
  position: absolute;
  font-size: 28px;
  right: 10px;
  top: 1px;
  color: #fff;
  display: block;
}
.MenuList {
  width: 100%;
  left: auto;
}
.Logo img {
  width: 300px;
}
.majlis a img {
  height: 150px !important;
}
  .navButtons .col-md-3 {
    width: 48%;
    margin-top: 0;
    margin-bottom: 1rem;
}
.audioDesign {
  width: 90%;
}
.ahamBayan h4 {
  font-size: 14px;
}
.audioDesign img {
  width: 70px;
  margin-top: -2px;
}
.audioDesign audio {
  margin: 0 0 10px;
}
.audioDesign h3 {
  font-size: 14px;
}

.nasharyat {
  display: none;
}
.navButtons a img {
  height: 100px;
}
.navButtons h1 {
  font-size: 20px;
}
.navButtons {
  padding: 3rem 0 2rem;
}
.Search_bar input[type="text"] {
  width: 150px;
}
.navButtons a {
  margin-right: 0px;
  margin-bottom: 1rem;
  padding: 1rem 0;
}
.shortClipsBox {
  padding-left: 4px;
  padding-right: 4px;
}
.Search_bar form {
  margin-left: 15px;
}
.featuredData {
  width: 100%;
  margin-bottom: 2rem;
}
.newBook .featuredData,
.white .featuredData,
.naya_shumara .col-md-6:nth-child(1) .featuredData {
  border-bottom: 2px solid #fff;
  padding-bottom: 2rem;
}
.shortClips {
  padding: 3rem 10px;
}
.ahamBayan {
  width: 100%;
}
.ahamBayan p {
  width: 100%;
}
.imageSlide {
  padding: 14px 0px;
  width: 100%;
}
  .col_reverse {
    flex-direction: column;
  }
  .MenuList {
    background-color: #fff;
    background-image: none !important;
    margin-top: -8.5rem;
    right: -150%;
    transition: 0.4s;
}
.imageSlide img {
  height: auto;
  width: 90%;
}
.shortClipsBox h3 {
  font-size: 14px;
  margin-bottom: 0;
}
.newBook p {
  max-width: 220px;
}
.newBook img {
  height: 150px;
}
.imageBg h1, .shortClips h1 {
  padding-right: 0;
  margin-bottom: 0.5rem;
}
.featuredImage .col-md-6 {
  margin-bottom: 1rem;
}
.MenuList ul {
  text-align: right;
}
ul.firstLevel li:before {
  content: "-";
  position: absolute;
  right: 0;
  top:10px;
  font-size: 20px;
  color: #333;
}
.MenuList li:hover ul {
  position: relative;
  top: auto;
  text-align: right;
  width: 100%;
  right: 1.5rem;
}
ul.firstLevel, ul.secondLevel {
  background-color: #fff;
}
.col_reverse span {
  display: none;
}
ul.firstLevel li a {
  height: 38px;
}
.firstLevel li:hover ul {
  position: relative;
  left: auto;
  padding-right: 0rem;
  width: auto;
}
.MenuList li i {
  left: 1rem;
  color: #333;
  float: left;
  top: 0.8rem;
  font-size: 16px;
  transform: rotate(-90deg);
}
.firstLevel li i {
  top: 0.8rem !important;
}
.MenuList li {
  border-bottom:1px solid #0a81915e;
}
ul.firstLevel li {
  border-bottom: 1px solid #0a81915e;
}
.MenuList li a {
  color: #333;
  font-size: 14px !important;
  height: 40px;
  justify-content: flex-end;
  width: 80%;
  margin-left: auto;
}
ul.firstLevel li:after{
  display: none;
}
.row {
  padding-left: 12px;
  padding-right: 12px;
}
.col-md-5, .col-md-2 {
  width: 100%;
}
.searchAll .col-md-2 {
  width: 48%;
}
.audioStyle {
  margin: 1rem 0px 0;
}
.dataFilter {
  justify-content: center;
}
.dataFilter select {
  width: 150px;
}
.ft_menu li {
  font-size: 14px;
}
.dataFilter {
  justify-content: left;
  flex-wrap: wrap;
  padding-left: 10px;
}
select#lDuration {
  margin-top: 1rem;
}
.pageTitle h1 {
  padding: 3rem 0 3rem;
}
.smallTitle {
  font-size: 22px;
}
.image_pdf img {
  height: auto;
}
.image_pdf {
  text-align: center;
  text-align: center;
}
#changeSrc {
  width: 90%;
  margin-top: 4rem;
}
#closeBtn {
  top: 1rem;
  margin-left: 0;
  right: 1rem;
}
.bookCard {
  margin-right: 0px;
}
.row.booksGrid .col-md-2,
.audio_row .col-md-2, 
.ImageGallery .col-md-2{
  width: 48%;
}
.row.bookDetail .col-md-3{
  width: 100%;
}

.des_side {
  padding-right: 0rem;
  margin-bottom: 2rem;
}
.des_side p {
  font-size: 14px;
}
.imageIcon img {
  height: 60px;
}
.activeAudio .col-md-3 {
  width: 100%;
}
.activeAudio .left_col {
  width: 100%;
  margin-bottom: 2rem;
}
.activeAudio p {
  font-size: 14px;
}
.socialSharing {
  position: absolute;
  right: 1rem;
  top: 1rem;
}
.ft_menu h1 {
  margin-bottom: 0;
  font-size: 26px;
  height: 38px;
  margin-top: 1.5rem;
}
.khatoot input, .khatoot textarea {
  width: 90%;
}
.khatoot h1 {
  font-weight: 500;
  font-size: 26px;
}


}

.live-audio {
  margin: 2rem auto 6rem;
  text-align: center;
}
