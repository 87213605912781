.toast-container{
   width:364px;
}
.Toastify__toast-body{
    padding: 0px;
}
.Toastify__close-button > svg {
    width: 0px;
}
.Toastify__toast{
    padding:0px;
    border-radius: 8px;
   
}
p{
   margin-bottom: 0px; 
}
.logo{
    width :90px;
    height:50px;
    border-radius: 10px;
    margin-left:15px;
}
.content{
    margin-right: 20px;
}
.content>h5{
font-size:17px;
margin-left: 10px;
font-weight: bold;
padding:0;
line-height: 10px;
color: rgb(151, 151, 151);
}

.content>p{
    font-size:12px;
    margin-left: 10px;
    line-height: 0px;
    }

.notification{
    display: flex;
    align-items: center;
    
}
.read{
 
    border-width:2px;
    border-left-style: solid;
    border-color: rgb(238, 235, 235);
    margin-left: 41px;
    padding-left:25px;

}
button.read-button{
    height: 66px;
    border: none;
    background-color: white;
    color: rgb(191, 185, 185);
    padding-left: 5px ;
}

